<template>
    <div>
        <!-- <div @click="onClickVolumeDetails" class="gl_button flex_center">添加类型</div> -->
        <el-table :data="tableData" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
            :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
            <el-table-column prop="name" label="类型" width="180">
            </el-table-column>
            <el-table-column prop="price" label="常规单价" width="180">
            </el-table-column>
            <el-table-column prop="sortId" label="排序">
            </el-table-column>
            <el-table-column prop="updatedTime" label="编辑时间">
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.state == 1">开放查询</div>
                    <div v-if="scope.row.state == 2">数据整理中</div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="onClickVolumeDetails(scope.row)" type="text">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- <div class="gl_pagination flex_center">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="10"
                :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
                :total="400">
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: {},
            total: 0,//总长度

        }
    },
    created() {
        this.getList()
    },
    mouted() {
    },
    methods: {
        async getList() {
            const { data: res } = await this.$http({
                url: "/sales/getList ",
                method: "POST",
                data: {},
            });
            this.tableData = res.content;
        },
        onClickVolumeDetails(item) {
            this.$router.push("/volumeDetails?id=" + item.id)
        },
    },
}
</script>
<style scoped>
.gl_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
    margin-bottom: 20px;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>